import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
// Import Framework7 React
import Framework7React from 'framework7-react';
import Template7 from 'template7';
import Dom7 from 'dom7';
import { f7ready} from 'framework7-react';

Framework7.use(Framework7React ) 

// Determine theme depending on device
var isAndroid = Framework7.prototype.device.android === true;
var isIos = Framework7.prototype.device.ios === true;
 
// Set Template7 global devices flags
Template7.global = {
    android: isAndroid,
    ios: isIos
};
 
// Define Dom7
var $$ = Dom7;

// Add CSS Styles

// Change Through navbar layout to Fixed
if (isAndroid) {
    // Change class
    $$('.view.navbar-through').removeClass('navbar-through').addClass('navbar-fixed');
    // And move Navbar into Page
    $$('.view .navbar').prependTo('.view .page');
}
 

 var timestamp   = Date.now();
// Init View

export const routes= [
{
    path: '/',
    name: 'home',
    on: {
      pageBeforeIn: () => {console.log("home page init - from routes"); }
    },
    redirect: function (route, resolve, reject) {
     
       
        resolve('/mobile/sessions_index/');
     
    } 
  },

{		
path: '/mobile/',
//url: '/mobile',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      var user_id = document.getElementById('userid').value;
      this.app.request.get("/mobile/",{foo:'bar',xid:6} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      
      
        const linePictureDiv = document.getElementById('profilepicturediv');
      const userPictureDiv = document.getElementById('userImage');
       const userPictureDiv2 = document.getElementById('profilepicturediv2');
    //  if (linePictureDiv != null) {
    	    if ((userPictureDiv != null) && userPictureDiv.firstElementChild) {
			userPictureDiv.removeChild(userPictureDiv.firstElementChild);
		}
		if ((userPictureDiv2 != null) && userPictureDiv2.firstElementChild) {
			userPictureDiv2.removeChild(userPictureDiv2.firstElementChild);
		}
      
      	if (linePictureDiv != null){
      		if (linePictureDiv.firstElementChild != null){
      			const img = document.createElement('img');
				img.src = linePictureDiv.firstElementChild.src;
		
				img.alt = "Profile Picture";
				img.id  = "lineUserImage";
				img.width = '32';
				img.height = '32';
				img.className = 'circular--square';
				userPictureDiv.appendChild(img);
			}
		}
      });
   	
   
    },
    on: {
  pageInit: function(page) {
  //  if (localStorage.redirect_url != '') {
  //  app.views.main.router.navigate(localStorage.redirect_url);
  //  localStorage.redirect_url = "";
  //  }
  }
}

},

{		
path: '/mobile/sessions_index/',
//url: '/mobile',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      var user_id = document.getElementById('userid').value;
      this.app.request.get("/mobile/sessions_index",{foo:'bar',xid:6} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      
      
        const linePictureDiv = document.getElementById('profilepicturediv');
      const userPictureDiv = document.getElementById('userImage');
       const userPictureDiv2 = document.getElementById('profilepicturediv2');
    //  if (linePictureDiv != null) {
    	    if ((userPictureDiv != null) && userPictureDiv.firstElementChild) {
			userPictureDiv.removeChild(userPictureDiv.firstElementChild);
		}
		if ((userPictureDiv2 != null) && userPictureDiv2.firstElementChild) {
			userPictureDiv2.removeChild(userPictureDiv2.firstElementChild);
		}
      
      	if (linePictureDiv != null){
      		if (linePictureDiv.firstElementChild != null){
      			const img = document.createElement('img');
				img.src = linePictureDiv.firstElementChild.src;
		
				img.alt = "Profile Picture";
				img.id  = "lineUserImage";
				img.width = '32';
				img.height = '32';
				img.className = 'circular--square';
				userPictureDiv.appendChild(img);
			}
		}
      });
   	
   
    },
    on: {
  pageInit: function(page) {
  //  if (localStorage.redirect_url != '') {
  //  app.views.main.router.navigate(localStorage.redirect_url);
  //  localStorage.redirect_url = "";
  //  }
  }
}
},
{
    path: '/right_panel_ajax/',
    panel: {
      url: '/mobile/right_panel',
      /* right-panel.html contains:
      <div class="panel panel-right panel-reveal">
        <div class="view">
          <div class="page">
            ...
          </div>
        </div>
      </div>
      */
    },
  },
{
    path: '/right_panel_store_ajax/',
    panel: {
      url: '/store/right_panel'
      /* right-panel.html contains:
      <div class="panel panel-right panel-reveal">
        <div class="view">
          <div class="page">
            ...
          </div>
        </div>
      </div>
      */
    },
  },  
  {
    path: '/line_items/:id/decrement/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/line_items/"+ routeTo.params.id + "/decrement" ,{} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }
  },  
   {
    path: '/orders/new/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/orders/new.js" ,{} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }
  }, 
  {
path: '/orders/user_index/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/orders/user_index" ,{} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }
}, 
   {
    path: '/carts/:cart_id/destroy/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/carts/"+ routeTo.params.cart_id + "/destroy" ,{} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }
  },  
{
path: "/mobile/show/:tutorial_id",
url: "/mobile/show/{{tutorial_id}}",
},
{
path: '/mobile/atmredirect/',
url: '/mobile/atmredirect',
},
{
path: '/mobile/invoice_link/',
url: '/mobile/invoice_link',
},
{
path: "/policy/",
url: "/policy",
},
{
path: '/chats/show/:id/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/chats/show/" + routeTo.params.id ,{} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }
},
{
path: '/store/',
url: '/store'
},
{
path: '/store/internships/',
url: '/store/internships'
},
{
path: '/store/about/',
url: '/store/about'
},
{
path: '/store/category/:id/',
url: '/store?id={{id}}'
},
{
path: '/store/reports/category/:id/',
url: '/store/reports?id={{id}}'
},
{
path: '/store/consultants/',
url:'store/consultants'
},
{
path: "/store/consult_reports/",
url: "/store/reports/",
},
{
path: "/store/report_show/:id",
url: "/store/report_show/{{id}}"
},
{
path: "/customer_internship/:id/edit",
url: "/customer_internship/{{id}}/edit"
},
{
path: "/consults/:id/reports/:page/:per_page",
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/consults/"+ routeTo.params.id + ".js" ,{page: routeTo.params.page ,per_page: routeTo.params.per_page} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }
},
{
path: "/appointments/list/",
url: "/appointments/list",
},
{
path: "/appointments/customer_list/",
url: "/appointments/customer_list",
},
{
path: '/consults/consultant_index/',
url: "/consults/consultant_index",
},
{
path: '/consults/new/',
url: "/consults/new",
},
{
path: '/consults/:id/consultant_edit/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/consults/"+ routeTo.params.id + "/consultant_edit" ,{id:  routeTo.params.id} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }
},
{
path: '/consults/:id/new_appointment',
url: "/consults/{{id}}/new_appointment.js?consult_id={{id}}"
},
{
path: '/consults/:id/appointment/:start_time/',
url: "/consults/{{id}}/new_appointment.js?consult_id={{id}}&date={{start_time}}"
},
{
path: '/consults/:consult_id/edit_appointment/:appointment_id/event/:event_id',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/consults/"+ routeTo.params.consult_id + "/edit_appointment.js" ,{appointment_id:  routeTo.params.appointment_id ,event_id: routeTo.params.event_id} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }
},
{
path: '/consults/:id',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/consults/"+ routeTo.params.id +".js" ,{} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }
},
{
path: '/line_items/:id',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.post("/line_items" ,{id:  routeTo.params.id} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }
},
{
path: '/products/:id',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/products/"+ routeTo.params.id +".js" ,{} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }
},
{
path: '/mobile/check/:tutorial_id/:type',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      var user_id = document.getElementById('userid').value;
      var credit_card_boolean;
      var user_name =  document.getElementById('user_name_input').value;
	  var phone_no =  document.getElementById('phone_no_input').value;
	  var userid =  document.getElementById('userid').value;
	  var address_city =  document.getElementById('address_city') ? document.getElementById('address_city').value : "" ;
	  var address_district =   document.getElementById('address_district') ? document.getElementById('address_district').value : "" ;
	  var address_zip_code =   document.getElementById('address_zip_code') ? document.getElementById('address_zip_code').value : "";
	  var address_street =  document.getElementById('address_street') ? document.getElementById('address_street').value : "";
	       
      if (routeTo.params.type == 'credit_card'){
      	credit_card_boolean = 'true'
      }
      
      this.app.request.get("/mobile/check" ,{address_city: address_city, address_district: address_district ,address_zip_code: address_zip_code, address_street: address_street ,userid: userid, phone_no: phone_no , user_name: user_name ,tutorial_id: routeTo.params.tutorial_id, foo:'bar',user_id: user_id ,credit_card: credit_card_boolean ,type: routeTo.params.type} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
        if (document.getElementById("_form_aiochk") != null) {
        document.getElementById("_form_aiochk").submit();
        }
      });
   }
},
{
path: '/logistics/create/:type/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      var user_id = document.getElementById('userid').value;
      var credit_card_boolean;
      var user_name =  document.getElementById('user_name_input').value;
	  var phone_no =  document.getElementById('phone_no_input').value;
	  var userid =  document.getElementById('userid').value;
	  var address_city =  document.getElementById('address_city') ? document.getElementById('address_city').value : "" ;
	  var address_district =   document.getElementById('address_district') ? document.getElementById('address_district').value : "" ;
	  var address_zip_code =   document.getElementById('address_zip_code') ? document.getElementById('address_zip_code').value : "";
	  var address_street =  document.getElementById('address_street') ? document.getElementById('address_street').value : "";
	       
      if (routeTo.params.type == 'credit_card'){
      	credit_card_boolean = 'true'
      }
      
      this.app.request.get("/logistics/create" ,{address_city: address_city, address_district: address_district ,address_zip_code: address_zip_code, address_street: address_street ,userid: userid, phone_no: phone_no , user_name: user_name ,tutorial_id: routeTo.params.tutorial_id, foo:'bar',user_id: user_id ,credit_card: credit_card_boolean ,type: routeTo.params.type} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
        if (document.getElementById("_form_aiochk") != null) {
        document.getElementById("_form_aiochk").submit();
        }
      });
   }
},
{
 path: '/login-screen/',
    /*
    We can load it from url like:
    url: 'login-screen.html'
    But in this example we load it just from content string
    */
   //url: "/mobile/tutorial/{{tutorial_id}}",
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      var user_id = document.getElementById('userid').value;
      this.app.request.get("/authentications/" ,{foo:'bar',user_id: user_id} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }, 
     on: {
  pageInit: function(page) {
  	var logined = '';
  //  logined = document.getElementById('customer_login').value;
  //  if (logined == ""){
  //  	 app.views.main.router.navigate("/login-screen/");
  //  } else
  //  {
  //  	localStorage.setItem("redirect_url" ,  "");
    	
  //  }
    }
  }
},
{
 path: '/customers/registrations/',
    /*
    We can load it from url like:
    url: 'login-screen.html'
    But in this example we load it just from content string
    */
   //url: "/mobile/tutorial/{{tutorial_id}}",
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      var user_id = document.getElementById('userid').value;
      this.app.request.get("/customers/sign_up" ,{foo:'bar',user_id: user_id} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }, 
     on: {
  pageInit: function(page) {
  	var logined = '';
  //  logined = document.getElementById('customer_login').value;
  //  if (logined == ""){
  //  	 app.views.main.router.navigate("/login-screen/");
  //  } else
  //  {
  //  	localStorage.setItem("redirect_url" ,  "");
    	
  //  }
    }
  }
},
{
 path: '/customers/forgot_password/',
    /*
    We can load it from url like:
    url: 'login-screen.html'
    But in this example we load it just from content string
    */
   //url: "/mobile/tutorial/{{tutorial_id}}",
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      var user_id = document.getElementById('userid').value;
      this.app.request.get("/customers/password/new" ,{foo:'bar',user_id: user_id} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }, 
     on: {
  pageInit: function(page) {
  	var logined = '';
  //  logined = document.getElementById('customer_login').value;
  //  if (logined == ""){
  //  	 app.views.main.router.navigate("/login-screen/");
  //  } else
  //  {
  //  	localStorage.setItem("redirect_url" ,  "");
    	
  //  }
    }
  }
},
{
 path: '/customers/edit/',
    /*
    We can load it from url like:
    url: 'login-screen.html'
    But in this example we load it just from content string
    */
   //url: "/mobile/tutorial/{{tutorial_id}}",
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      var user_id = document.getElementById('userid').value;
      this.app.request.get("/customers/edit" ,{foo:'bar',user_id: user_id} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }, 
     on: {
  pageInit: function(page) {
  	var logined = '';
  //  logined = document.getElementById('customer_login').value;
  //  if (logined == ""){
  //  	 app.views.main.router.navigate("/login-screen/");
  //  } else
  //  {
  //  	localStorage.setItem("redirect_url" ,  "");
    	
  //  }
    }
  }
},
{
 path: '/mobile/payments/',
    /*
    We can load it from url like:
    url: 'login-screen.html'
    But in this example we load it just from content string
    */
   //url: "/mobile/tutorial/{{tutorial_id}}",
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      var user_id = document.getElementById('userid').value;
      this.app.request.get("/mobile/payments" ,{foo:'bar',user_id: user_id} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   }, 
     on: {
  pageInit: function(page) {
  	var logined = '';
  //  logined = document.getElementById('customer_login').value;
  //  if (logined == ""){
  //  	 app.views.main.router.navigate("/login-screen/");
  //  } else
  //  {
  //  	localStorage.setItem("redirect_url" ,  "");
    	
  //  }
    }
  }
},
{
path: '/customers/sign_out/',
 
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      var user_id = document.getElementById('userid').value;
      this.app.request.get("/customers/sign_out" ,{foo:'bar',user_id: user_id} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
         f7ready((f7) => {
            f7.views.main.router.navigate({
         	name: 'home' ,
         	path: '/',
         	reloadAll: true
         	});
         });
      });
   
   
   } 
},
{
    path: '/sign_in/',
 
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      var user_id = document.getElementById('userid').value;
      this.app.request.get("/customers/sign_in" ,{foo:'bar',user_id: user_id} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
         app.views.main.router.navigate("/login-screen");
      });
   
   } 
},
{
path: "/mobile/tutorial/:tutorial_id",
//url: "/mobile/tutorial/{{tutorial_id}}",
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
   //   localStorage.setItem("redirect_url" ,  "/mobile/tutorial/" + routeTo.params.tutorial_id);
           // Get external data and return template7 template
      var user_id = document.getElementById('userid').value;
      var accessToken = document.getElementById('accessToken').value;
      this.app.request.get("/mobile/tutorial/" + routeTo.params.tutorial_id  ,{foo:'bar',user_id: user_id ,accessToken: accessToken} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   },
    on: {
  pageInit: function(page) {
  	var logined = '';
  //  logined = document.getElementById('customer_login').value;
  //  if (logined == ""){
  //  	 app.views.main.router.navigate("/login-screen/");
  //  } else
  //  {
  //  	localStorage.setItem("redirect_url" ,  "");
    	
  //  }
    }
  }
},
{
path: "/mobile/order/:tutorial_id",
//url: "/mobile/tutorial/{{tutorial_id}}",
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
   //   localStorage.setItem("redirect_url" ,  "/mobile/tutorial/" + routeTo.params.tutorial_id);
           // Get external data and return template7 template
      var user_id = document.getElementById('userid').value;
      var accessToken = document.getElementById('accessToken').value;
      this.app.request.get("/mobile/order/" + routeTo.params.tutorial_id  ,{foo:'bar',user_id: user_id ,accessToken: accessToken} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
   },
    on: {
  pageInit: function(page) {
  	var logined = '';
  //  logined = document.getElementById('customer_login').value;
  //  if (logined == ""){
  //  	 app.views.main.router.navigate("/login-screen/");
  //  } else
  //  {
  //  	localStorage.setItem("redirect_url" ,  "");
    	
  //  }
    }
  }
},
{
path: '/mobile/schedules/',
url: '/mobile/schedules/',
},
{
path: "/mobile/queryaccount/:tutorial_id",
  async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
       // localStorage.setItem("redirect_url" , "/mobile/");

      // Get external data and return template7 template
      var user_id = document.getElementById('userid').value;
    
      this.app.request.get('/mobile/queryaccount/'+ routeTo.params.tutorial_id ,{foo:'bar',line_id: user_id } , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      });
    }
},
{
path: '/consult_reports/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/consult_reports.js" ,{} , function (data) {
        resolve(
        
          {
           content: `<div class="page">${data}</div>`
          }
        );
      });
   }
},
{
path: '/task_reports/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/task_reports.js" ,{} , function (data) {
        resolve(
        
          {
           content: `<div class="page">${data}</div>`
          }
        );
      });
   }
},
{
path: '/tasks/:task_id/task_reports/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/task_reports.js" + "?task_id="+ routeTo.params.task_id ,{} , function (data) {
        resolve(
        
          {
           content: `<div class="page">${data}</div>`
          }
        );
      });
   }
},
{
path: '/consult_reports/customer/:customer_id/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/consult_reports.js" + "?customer_id="+ routeTo.params.customer_id ,{} , function (data) {
        resolve(
        
          {
           content: `<div class="page">${data}</div>`
          }
        );
      });
   }
},
{
path: '/task_reports/customer/:customer_id/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/task_reports.js" + "?customer_id="+ routeTo.params.customer_id ,{} , function (data) {
        resolve(
        
          {
           content: `<div class="page">${data}</div>`
          }
        );
      });
   }
},
{
path: '/consult_reports/:page/page/customer_id/:customer_id/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/consult_reports.js" + "?page=" +  routeTo.params.page +  "&customer_id=" +  routeTo.params.customer_id,{} , function (data) {
        resolve(
        
          {
           content: `<div class="page">${data}</div>`
          }
        );
      });
   }
},
{
path: '/task_reports/:page/page/customer_id/:customer_id/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/task_reports.js" + "?page=" +  routeTo.params.page +  "&customer_id=" +  routeTo.params.customer_id,{} , function (data) {
        resolve(
        
          {
           content: `<div class="page">${data}</div>`
          }
        );
      });
   }
},
{
path: '/consult_reports/:page/page/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/consult_reports.js" + "?page=" +  routeTo.params.page ,{} , function (data) {
        resolve(
        
          {
           content: `<div class="page">${data}</div>`
          }
        );
      });
   }
},
{
path: '/task_reports/:page/page/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/task_reports.js" + "?page=" +  routeTo.params.page ,{} , function (data) {
        resolve(
        
          {
           content: `<div class="page">${data}</div>`
          }
        );
      });
   }
},
{
path: '/consult_reports/:id/edit/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/consult_reports/" + routeTo.params.id + "/edit.js" ,{} , function (data) {
        resolve(
        
          {
           content: `<div class="page">${data}</div>`
          }
        );
      });
   }
},
{
path: '/task_reports/:id/edit/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/task_reports/" + routeTo.params.id + "/edit.js" ,{} , function (data) {
        resolve(
        
          {
           content: `<div class="page">${data}</div>`
          }
        );
      });
   }
},
{
path: '/tutorials/:tutorial_id/lessons/:id',
url: '/tutorials/{{tutorial_id}}/lessons/{{id}}',
},
{
path: '/consult_reports/:id/comment_new/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      this.app.request.get("/consult_reports/" + routeTo.params.id + "/comment_new.js" ,{} , function (data) {
	  
	f7ready(($f7) => {
    var popup = $f7.popup.create({
  	content: '<div class="popup"><div class="view">' +
    '<div class="page">' +
      '<div class="navbar">' +
        '<div class="navbar-bg"></div>' +
        '<div class="navbar-inner">' +
          '<div class="title">請輸入回覆訊息</div>' +
          '<div class="right">' +
            '<!-- Link to close popup -->' +
            '<a class="link popup-close">Close</a>' +
          '</div>' +
        '</div>' +
      '</div>' +
      '<div class="page-content">' +  `<div>${data}</div>` +
 //      ' ...' + '<div id="my-text-editor"></div>' +
      '</div>' +
    '</div>' +
  	'</div></div>',
  		on: {
    			opened: function () {
      		console.log('Popover opened')
      
    
   				$$("#popup_script").find("script").each(function (el) {
    					if ($$(this).attr('src')) {
      				var s = document.createElement('script');
      				s.src = $$(this).attr('src');
    					//  $$('head').append(s);
    					} else {
      				eval($$(this).text());
    					}
  				});
    								}
  			}
	}) //popup create
		popup.open();
		var textEditor = $f7.textEditor.create({
  					el: '#my-text-editor',
  					placeholder: '請輸入'
					});
					
			
	});
  }); //request
   } //async
},
{
path: '/task_reports/:id/comment_new/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      this.app.request.get("/task_reports/" + routeTo.params.id + "/comment_new.js" ,{} , function (data) {
	  
	f7ready(($f7) => {
    var popup = $f7.popup.create({
  	content: '<div class="popup"><div class="view">' +
    '<div class="page">' +
      '<div class="navbar">' +
        '<div class="navbar-bg"></div>' +
        '<div class="navbar-inner">' +
          '<div class="title">請輸入回覆訊息</div>' +
          '<div class="right">' +
            '<!-- Link to close popup -->' +
            '<a class="link popup-close">Close</a>' +
          '</div>' +
        '</div>' +
      '</div>' +
      '<div class="page-content">' +  `<div>${data}</div>` +
 //      ' ...' + '<div id="my-text-editor"></div>' +
      '</div>' +
    '</div>' +
  	'</div></div>',
  		on: {
    			opened: function () {
      		console.log('Popover opened')
      
    
   				$$("#popup_script").find("script").each(function (el) {
    					if ($$(this).attr('src')) {
      				var s = document.createElement('script');
      				s.src = $$(this).attr('src');
    					//  $$('head').append(s);
    					} else {
      				eval($$(this).text());
    					}
  				});
    								}
  			}
	}) //popup create
		popup.open();
		var textEditor = $f7.textEditor.create({
  					el: '#my-text-editor',
  					placeholder: '請輸入'
					});
					
			
	});
  }); //request
   } //async
},
{
path: '/consult_reports/:id/comment_edit/:comment_id',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      this.app.request.get("/consult_reports/" + routeTo.params.id + "/comment_edit?" + "comment_id=" + routeTo.params.comment_id  ,{} , function (data) {
	  
	f7ready(($f7) => {
    var popup = $f7.popup.create({
  	content: '<div class="popup"><div class="view">' +
    '<div class="page">' +
      '<div class="navbar">' +
        '<div class="navbar-bg"></div>' +
        '<div class="navbar-inner">' +
          '<div class="title">請輸入回覆訊息</div>' +
          '<div class="right">' +
            '<!-- Link to close popup -->' +
            '<a class="link popup-close">Close</a>' +
          '</div>' +
        '</div>' +
      '</div>' +
      '<div class="page-content">' +  `<div>${data}</div>` +
 //      ' ...' + '<div id="my-text-editor"></div>' +
      '</div>' +
    '</div>' +
  	'</div></div>',
  		on: {
    			opened: function () {
      		console.log('Popover opened')
      
    
   				$$("#popup_script").find("script").each(function (el) {
    					if ($$(this).attr('src')) {
      				var s = document.createElement('script');
      				s.src = $$(this).attr('src');
    					//  $$('head').append(s);
    					} else {
      				eval($$(this).text());
    					}
  				});
    								}
  			}
	}) //popup create
		popup.open();
		var textEditor = $f7.textEditor.create({
  					el: '#my-text-editor',
  					value: $$('#comment_body').html() ,
					});
					
			
	});
  }); //request
   } //async
},
{
path: '/task_reports/:id/comment_edit/:comment_id',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      this.app.request.get("/task_reports/" + routeTo.params.id + "/comment_edit?" + "comment_id=" + routeTo.params.comment_id  ,{} , function (data) {
	  
	f7ready(($f7) => {
    var popup = $f7.popup.create({
  	content: '<div class="popup"><div class="view">' +
    '<div class="page">' +
      '<div class="navbar">' +
        '<div class="navbar-bg"></div>' +
        '<div class="navbar-inner">' +
          '<div class="title">請輸入回覆訊息</div>' +
          '<div class="right">' +
            '<!-- Link to close popup -->' +
            '<a class="link popup-close">Close</a>' +
          '</div>' +
        '</div>' +
      '</div>' +
      '<div class="page-content">' +  `<div>${data}</div>` +
 //      ' ...' + '<div id="my-text-editor"></div>' +
      '</div>' +
    '</div>' +
  	'</div></div>',
  		on: {
    			opened: function () {
      		console.log('Popover opened')
      
    
   				$$("#popup_script").find("script").each(function (el) {
    					if ($$(this).attr('src')) {
      				var s = document.createElement('script');
      				s.src = $$(this).attr('src');
    					//  $$('head').append(s);
    					} else {
      				eval($$(this).text());
    					}
  				});
    								}
  			}
	}) //popup create
		popup.open();
		var textEditor = $f7.textEditor.create({
  					el: '#my-text-editor',
  					value: $$('#comment_body').html() ,
					});
					
			
	});
  }); //request
   } //async
},
{
path: '/appointments/:id/comment_new/',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      this.app.request.get("/appointments/" + routeTo.params.id + "/comment_new.js" ,{} , function (data) {
	  
	f7ready(($f7) => {
    var popup = $f7.popup.create({
  	content: '<div class="popup"><div class="view">' +
    '<div class="page">' +
      '<div class="navbar">' +
        '<div class="navbar-bg"></div>' +
        '<div class="navbar-inner">' +
          '<div class="title">請輸入回覆訊息</div>' +
          '<div class="right">' +
            '<!-- Link to close popup -->' +
            '<a class="link popup-close">Close</a>' +
          '</div>' +
        '</div>' +
      '</div>' +
      '<div class="page-content">' +  `<div>${data}</div>` +
 //      ' ...' + '<div id="my-text-editor"></div>' +
      '</div>' +
    '</div>' +
  	'</div></div>',
  		on: {
    			opened: function () {
      		console.log('Popover opened')
      
    
   				$$("#popup_script").find("script").each(function (el) {
    					if ($$(this).attr('src')) {
      				var s = document.createElement('script');
      				s.src = $$(this).attr('src');
    					//  $$('head').append(s);
    					} else {
      				eval($$(this).text());
    					}
  				});
    								}
  			}
	}) //popup create
		popup.open();
		var textEditor = $f7.textEditor.create({
  					el: '#my-text-editor',
  					placeholder: '請輸入'
					});
					
			
	});
  }); //request
   } //async
},
{
path: '/lessons/:id/new_comment/:parent_id',
async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
      this.app.request.get("/lessons/" + routeTo.params.id + "/new_comment.js?" + 'parent_id=' + routeTo.params.parent_id ,{} , function (data) {
	  $("#reply-form-"+routeTo.params.parent_id).html(data);
	 
  }); //request
   } //async
},

 {
    path: '/consult_reports/new/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/consult_reports/new.js" ,{} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      
        
        
        
        
      });
   }
  }, 
  
 {
    path: '/task_reports/new/:task_id/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
      console.log(routeTo);
      // Get external data and return template7 template
   
	       
    
      
      this.app.request.get("/task_reports/new.js?" + 'task_id=' + routeTo.params.task_id  ,{} , function (data) {
        resolve(
          // How and what to load: template
          {
            template: '<div class="page">{{users}}</div>'
          },
          // Custom template context
          {
            context: {
              users: data,
            },
          }
        );
      
        
        
        
        
      });
   }
  }, 
]

 f7ready(($f7) => {
  
  	
 




$$(document).on('page:afterin', function (e, page) {
	
   $$(".page-current").find("script").each(function (el) {
    if ($$(this).attr('src')) {
      var s = document.createElement('script');
      s.src = $$(this).attr('src');
      $$('head').append(s);
    }
    else if ($$(this).attr('type')) { 
    } else {	
      eval($$(this).text());
    }
  });
  ReactOnRails.reactOnRailsPageLoaded();
});


});